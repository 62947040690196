import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import IllustrationPreview from 'components/IllustrationPreview';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Chameleon illustrations provide visual storytelling and branding possibilities.
They can portray a certain mood or style for your product and they can be
utilized as metaphors to make complex aspects of the product more clear and
understandable.`}</p>
    <p>{`Each brand can pick or create their own illustration style. We do advise however
to be consistent: choose one style for all your illustrations. This way the user
gets familiar with your illustrations and will easily recognize them as being
part of your brand and understand the functional meaning they may provide.`}</p>
    <p>{`A few examples of illustration styles:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f04d41847881e7e7c250362d85a4e929/1e496/styles.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.571428571428573%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsSAAALEgHS3X78AAAA+0lEQVQI12P4//8/49Pm9vaHdq67N8ooSSX9/y+44MLtdctuPW1lgIIzl07UnT2TvyK3nIEHqJ7z1KH7q47MPLX6f7M5y6+v30/8+P7t/4sbl3/euPH8D0Ovi3vi5+cv/oPA+xs3Znk//m+19vv//9Oefv9tfu2/xP9Xz3la9p2+3rFq8ScGSWEZoDL2h0+ef963fvePgzZVnP///bp09enr/7v3HP1/4/i2/wy+YaF6R04cr/8NNOPH71/2DC0/maNufg8Pu/7V5cTbz4xAAxg27z+YdOnygwSQb0AuvnJtnsb+I4uKQOwfnx9NP3z6yvHrB07s/fdg8z4AEsOcHmkvmPIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Illustration Styles",
            "title": "Illustration Styles",
            "src": "/static/f04d41847881e7e7c250362d85a4e929/8c557/styles.png",
            "srcSet": ["/static/f04d41847881e7e7c250362d85a4e929/4edbd/styles.png 175w", "/static/f04d41847881e7e7c250362d85a4e929/13ae7/styles.png 350w", "/static/f04d41847881e7e7c250362d85a4e929/8c557/styles.png 700w", "/static/f04d41847881e7e7c250362d85a4e929/e996b/styles.png 1050w", "/static/f04d41847881e7e7c250362d85a4e929/2cefc/styles.png 1400w", "/static/f04d41847881e7e7c250362d85a4e929/1e496/styles.png 3268w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "the-default-chameleon-illustration-set",
      "style": {
        "position": "relative"
      }
    }}>{`The default Chameleon illustration set`}</h2>
    <p>{`Chameleon provides a white-label illustration set which will be used as a
fallback/ default for all the illustrations that are currently used in the
digital products that are linked to the design system.`}</p>
    <p>{`The white label set is designed in grey tones to have it fit any brand. However,
brands can choose to keep this illustration set but have it branded in their own
colors. Browse the available illustrations below.`}</p>
    <p><strong parentName="p">{`Note`}</strong>{`: This overview also provides a set of all the illustrations that are
currently necessary for the app, in case you choose to have your own set of
illustrations designed.`}</p>
    <IllustrationPreview mdxType="IllustrationPreview" />
    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`The illustrations are created within a 3:2 ratio container and ready to use
within all sizes that fit that ratio. Because of the vector-based (SVG) file
type, they will not lose quality after resizing.`}</p>
    <p>{`PNG file formats do have a fixed size so make sure to check the quality if you
want to use that file type.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7b5d263595cd962c22022ae925db66c6/52ab5/usage.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.28571428571428%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsSAAALEgHS3X78AAAB20lEQVQ4y41UzWoiQRD2IZJH8AE8efNtBE9ZfAZPehYvPsAmiwqKgngThL3sIQFz9KIuoo6ZoDPjTM9Mf+mqnt8oyzYU1V1d9dVX1T8Fx5LwBSA8CRFpWgc+WHtuCDeSeC/xFVJLZHevEgWaABJ6aO0rNMexYdsWPj5OOJ0MGMYR5qcJKcOcb1aHaqtA6LyUkoXG5XJhvdlsMJlMeH48HjGbzVRQyEKuOgZJXBAohkJE+JkNy7LgeR5WqxUWiwXbDMPAcDhUQUECmhJBBEgMxX2G2+0Wu91O9c5l8PV6jdFohOv1ymAEHPunDBEzlLlNoYy+7+NwOKDX66HdbqPb7aLf7+N8PnMC8suyTAG9fBbdn7R0YtVsNlkGgwEzdhznpux/AsaOFDidTtHpdFjG4zHv2bad9PK/SiZnGsRmv9/zgZimyf2LE8XJ7wDeHkpcNmnqF/WNgOfzOVqtFmq1GidJfXGv5Py9ypZE83q9jlKphGKxiHK5jEajcdNzvof5l/L95od84jSenn7g4fEBlUoF1WoVLy/PGX8tITGk90dECD0MtDHV6hn6muVy+Ybnn7/U6/mbpI7jqFSaU/sKnqsNgS/5Q8jpQH8Cr79DvP9JaxCCPorgJoba9wWrryWbaF44IgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Figma Libraries",
            "title": "Figma Libraries",
            "src": "/static/7b5d263595cd962c22022ae925db66c6/8c557/usage.png",
            "srcSet": ["/static/7b5d263595cd962c22022ae925db66c6/4edbd/usage.png 175w", "/static/7b5d263595cd962c22022ae925db66c6/13ae7/usage.png 350w", "/static/7b5d263595cd962c22022ae925db66c6/8c557/usage.png 700w", "/static/7b5d263595cd962c22022ae925db66c6/e996b/usage.png 1050w", "/static/7b5d263595cd962c22022ae925db66c6/2cefc/usage.png 1400w", "/static/7b5d263595cd962c22022ae925db66c6/52ab5/usage.png 1420w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Please check out the `}<a parentName="p" {...{
        "href": "/components/illustration/code"
      }}>{`guidelines`}</a>{` for further
information on how to use the illustrations as components.`}</p>
    <h2 {...{
      "id": "open-source-illustration-sets",
      "style": {
        "position": "relative"
      }
    }}>{`Open source illustration sets`}</h2>
    <p>{`There are a few open-source libraries online of which you can use illustrations
for free. We can recommend:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.manypixels.co/gallery/"
        }}>{`https://www.manypixels.co/gallery/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://undraw.co/illustrations"
        }}>{`https://undraw.co/illustrations`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://illlustrations.co/"
        }}>{`https://illlustrations.co/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.drawkit.io/"
        }}>{`https://www.drawkit.io/`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.humaaans.com/"
        }}>{`https://www.humaaans.com/`}</a></li>
    </ul>
    <p>{`If you decide to use any of these the design system team can change them for you
into the colors of your brand.`}</p>
    <h2 {...{
      "id": "not-finding-the-right-illustration",
      "style": {
        "position": "relative"
      }
    }}>{`Not finding the right illustration?`}</h2>
    <p>{`Chameleon Design System is a living thing. Therefore, the situation may occur
that certain illustrations do not yet exist. Please see our
`}<a parentName="p" {...{
        "href": "/about/team/"
      }}>{`Getting in touch`}</a>{` page for information on how to reach out. If
there's a similar illustration already, we will suggest you to use a existing
one instead.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      